import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Resources & Events" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>Resources & Events</h1>     
      </Container>
    </div>
    <Container className="singleCol two-rem-padding">
      <Row>
        <Col lg={6}>
          <h2>Resources</h2>
          <ul className="no-bullet">
            <li><a href="https://www.slingerland.org">The Slingerland Literacy Institute</a></li>
            <li><a href="https://dyslexiaida.org">International Dyslexia Association</a></li>
            <li><a href="https://www.dyslexiatraininginstitute.org">Dyslexia Training Institute</a></li>
            <li><a href="https://decodingdyslexiaca.org">Decoding Dyslexia CA</a></li>
            <li><a href="https://www.matrixparents.org">Matrix Parent Network</a></li>
            <li><a href="https://imse.com">Institute for Multisensory Education</a></li>
            <li><a href="https://edsource.org">Edsource</a></li>
            <li><a href="https://www.careads.org">CA Reading Coalition</a></li>
            <li><a href="https://www.gettingsmart.com">Getting Smart</a></li>
            <li><a href="https://www.chconline.org">Children's Health Council</a></li>
            <li><a href="https://www.ncld.org">National Center for Learning Disabilities</a></li>
            <li><a href="https://www.understood.org">Understood</a></li>
            <li><a href="https://dyslexia.yale.edu">The Yale Center for Dyslexia & Creativity</a></li>
            <li><a href="http://dyslexiahelp.umich.edu">Dyslexia Help at University of Michigan</a></li>
          </ul>
        </Col>
        <Col lg={6}>
          <h2>Events</h2>
          <ul className="no-bullet">
            <li><a href="https://dyslexiaida.org/conference/">International Dyslexia Association Annual Conference</a></li>
          </ul>
          
        </Col>
      </Row>
      
    </Container>
    <Container className="singleCol two-rem-padding">
    <h2>News Articles</h2>
    <ul className="no-bullet">
      <li>
        <a href="https://www.sfexaminer.com/opinion/opinion-early-reading-instruction-in-san-francisco-public-schools-a-love-affair-with-what-has-failed/">
        Opinion: Early reading instruction in San Francisco public schools: A love affair with what has failed
        </a>
      </li>
    </ul>
    </Container>
  </Layout>
)

export default IndexPage
